<template>
    <li :class="{'padded': padded, 'no-border': noBorder}">
        <slot/>
    </li>
</template>

<script>
    export default {
        name: 'ContainerListItem',
        props: {
            padded: Boolean,
            noBorder: Boolean
        }
    }
</script>

<style lang="scss" scoped>
    li {
        border-bottom: 1px solid #e7e7e7 !important;
        border-left: 0 !important;

        cursor: auto !important;

        font-size: 0.85rem !important;

        &.padded {
            padding: 21px 15px 2rem 15px;
        }

        &.no-border {
            border: 0;
        }

        &:hover {
            background-color: #fafafa !important;
        }
    }
</style>
