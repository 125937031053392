<template>
    <div>
        <card-header title="New Response" icon="fa-plus"/>

        <card-body>
            <card-list>
                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Participant" label-position="inside">
                                <b-select expanded v-model="newQuestion.user_id">
                                    <option :value="registration.user.id">{{registration.user.last_name}}, {{registration.user.first_name}} (Primary)</option>
                                    <option :value="registration.second_user.id" v-if="registration.second_user">{{registration.second_user.last_name}}, {{registration.second_user.first_name}} (Secondary)</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Trip Question" label-position="inside">
                                <b-select expanded v-model="newQuestion.question" @change.native="clear">
                                    <option :value="a" v-for="a in tripQuestions">{{a.question}}</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column" v-if="newQuestion.question && newQuestion.question.answers.length">
                            <b-field label="Response" label-position="inside">
                                <b-select expanded v-model="newQuestion.response">
                                    <option :value="{id: a.id, response: a.answer}" v-for="a in newQuestion.question.answers">{{a.answer}}</option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column" v-else>
                            <b-field label="Response" label-position="inside">
                                <b-input v-model="newQuestion.response" />
                            </b-field>
                        </div>
                    </div>
                </container-list-item>
            </card-list>

        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" @click="submit" :disabled="!newQuestion.question || !newQuestion.user_id"><i class="fas fa-plus mr-3"></i>New Response</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardBody from '../../TIER/components/CardBody';
    import CardFooter from '../../TIER/components/CardFooter';

    import {client as http} from '../../http_client';
    import CardList from "@/TIER/components/CardList";
    import ContainerListItem from "@/TIER/components/ContainerListItem";
    import async from "async";

    export default {
        props: ['card', 'props'],
        components: {ContainerListItem, CardList, CardFooter, CardBody, CardHeader},
        data: function () {
            return {
                newQuestion: {},
                tripQuestions: [],
                registration: {
                    user: {}
                }
            };
        },
        methods: {
            loadQuestions: function(force) {
                this.$emit('loading', true);

                async.series([
                    // Get response
                    (cb) => {
                        http.get('/api/trips/' + this.props.tripId + '/questions/0/500', {force}).then(response => {
                            this.tripQuestions = response.data.questions;
                            cb();
                        });
                    },
                    // Get registration
                    (cb) => {
                        http.get('/api/registrations/' + this.props.registrationId, {force: true}).then(response => {
                            this.registration = response.data;
                            cb();
                        });
                    }
                ], err => {
                    this.$emit('loading', false);
                });

            },
            submit() {
                this.$emit('loading', true);
                http.post('/api/registrations/' + this.props.registrationId + '/responses', {
                    question_id: this.newQuestion.question.id,
                    user_id: this.newQuestion.user_id,
                    answer_id: (this.newQuestion.response && this.newQuestion.response.id) ? this.newQuestion.response.id : null,
                    response: (this.newQuestion.response && this.newQuestion.response.id) ? this.newQuestion.response.response : this.newQuestion.response
                }, {force: true}).then(response => {
                    this.$reloadCard('registrations');
                    this.$reloadCard('responses', {registrationId: this.props.registrationId});
                    this.$reloadCard('registration', {registrationId: this.props.registrationId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            clear() {
                this.newQuestion.response = null;
            }
        },
        mounted() {
            this.newQuestion.registration_id = this.props.registrationId;
            this.$saveAlert(true, 'Are you done creating this new response? Unsaved changes will be lost.');
            this.loadQuestions();
        }
    };
</script>
